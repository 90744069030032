<template>
  <div class="pos_rel h_160 bg_col_lin_2B8EFF_56_per">
    <div class="dis_flex m_t_32">
      <div
        v-for="(item, index) in [
          { label: '基本信息填写', value: 1 },
          { label: '个人信息填写', value: 2 },
          { label: '车辆信息填写', value: 3 },
        ]"
        :key="index"
        :class="`flex_1 dis_flex flex_dir_col ali_it_cen ${
          index == step ? '' : 'opa_50_per'
        }`"
      >
        <div
          :class="`            
            dis_flex
            ju_con_cen
            ali_it_cen
            col_2396F5
            bg_col_fff
            bor_rad_50_per
            ${
              index == step
                ? 'w_40 h_40 font_18 bor_solid_4_7cb8fe m_t_neg_12'
                : 'w_24 h_24 font_14'
            }
          `"
        >
          {{ item.value }}
        </div>

        <!-- 标题 -->
        <div :class="`font_14 col_fff ${
              index == step
                ? 'm_t_4'
                : 'm_t_16'
            }`">{{ item.label }}</div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 虚线 左 -->
    <div
      class="pos_abs l_50_per bor_b_dashed_2_255_255_255_50_per"
      style="width: 16vw; top: 42px; transform: translateX(-25vw)"
    ></div>

    <!-- 虚线 右 -->
    <div
      class="pos_abs l_50_per w_64 bor_b_dashed_2_255_255_255_50_per"
      style="width: 16vw; top: 42px; transform: translateX(9vw)"
    ></div>

    <!--  -->
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: () => 0,
    },

    //
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
