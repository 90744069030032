var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pos_rel h_160 bg_col_lin_2B8EFF_56_per"},[_c('div',{staticClass:"dis_flex m_t_32"},_vm._l(([
        { label: '基本信息填写', value: 1 },
        { label: '个人信息填写', value: 2 },
        { label: '车辆信息填写', value: 3 } ]),function(item,index){return _c('div',{key:index,class:("flex_1 dis_flex flex_dir_col ali_it_cen " + (index == _vm.step ? '' : 'opa_50_per'))},[_c('div',{class:("            \n          dis_flex\n          ju_con_cen\n          ali_it_cen\n          col_2396F5\n          bg_col_fff\n          bor_rad_50_per\n          " + (index == _vm.step
              ? 'w_40 h_40 font_18 bor_solid_4_7cb8fe m_t_neg_12'
              : 'w_24 h_24 font_14') + "\n        ")},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{class:("font_14 col_fff " + (index == _vm.step
              ? 'm_t_4'
              : 'm_t_16'))},[_vm._v(_vm._s(item.label))])])}),0),_c('div',{staticClass:"pos_abs l_50_per bor_b_dashed_2_255_255_255_50_per",staticStyle:{"width":"16vw","top":"42px","transform":"translateX(-25vw)"}}),_c('div',{staticClass:"pos_abs l_50_per w_64 bor_b_dashed_2_255_255_255_50_per",staticStyle:{"width":"16vw","top":"42px","transform":"translateX(9vw)"}})])}
var staticRenderFns = []

export { render, staticRenderFns }